import React from "react"
import { Helmet } from "react-helmet"

import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import * as styles from "../styles/home.module.css"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { gsap } from "gsap"

const { useEffect, useLayoutEffect, useRef, useState } = React

export default function Home({ data }) {
  const [showChild, setShowChild] = useState(false)

  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    return <Layout />
  }

  return <Child {...data} />
}

function Child(data) {
  const hero = getImage(data.hero.childImageSharp.gatsbyImageData)

  const title = useRef()
  const img = useRef()
  const btn = useRef()

  const el = useRef()
  const q = gsap.utils.selector(el)

  useLayoutEffect(() => {
    const tl = gsap.timeline()

    tl.addLabel("same")
      .from(title.current, { duration: 1.5, y: 150, opacity: 0 }, "same")
      .from(img.current, { duration: 2, scale: 2, opacity: 0 }, "same")
      .from(
        q(".icon"),
        {
          duration: 1,
          y: 150,
          stagger: 0.2,
          opacity: 0,
          scale: 2,
          ease: "back",
        },
        "-=1"
      )
      .from(
        btn.current,
        { duration: 1, scale: 0.5, opacity: 0, ease: "rough" },
        "-=0.5"
      )
  }, [])

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="Index page of Patrick Oldman's web developer portfolio website."
        />
        <title>Patrick Oldman | Home</title>
      </Helmet>
      <section className={styles.home}>
        <div className={styles.text} ref={title}>
          <h2>Patrick Oldman</h2>
          <h3>Full Stack Web Developer</h3>
        </div>
        <div className={styles.icons} ref={el}>
          <img src={"icons/html.png"} className="icon" alt="" />
          <img src={"icons/css.png"} className="icon" alt="" />
          <img src={"icons/php.png"} className="icon" alt="" />
          <img src={"icons/js.png"} className="icon" alt="" />
          <img src={"icons/python.png"} className="icon" alt="" />
          <img src={"icons/jquery.png"} className="icon" alt="" />
          <img src={"icons/symfony.png"} className="icon" alt="" />
          <img src={"icons/bootstrap.png"} className="icon" alt="" />
          <img src={"icons/materialize.png"} className="icon" alt="" />
          <img src={"icons/mysql.png"} className="icon" alt="" />
          <img src={"icons/react.png"} className="icon" alt="" />
          <img src={"icons/gatsby.png"} className="icon" alt="" />
          <img src={"icons/mongodb.png"} className="icon" alt="" />
          <img src={"icons/heroku.png"} className="icon" alt="" />
          <img src={"icons/flask.png"} className="icon" alt="" />
          <img src={"icons/django.png"} className="icon" alt="" />
        </div>
        <div className={styles.btn} ref={btn}>
          <Link to="/projects">View My Portfolio Projects</Link>
        </div>
        <div className={styles.homeImg} ref={img}>
          <GatsbyImage image={hero} alt="Patrick-Bitmoji" />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Home {
    hero: file(relativePath: { eq: "patmoji.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
